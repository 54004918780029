<template>
	<div class="body">
		<div>
			<div style="margin-bottom: 10px;">
				<a-form-model layout="inline" :model="search" class="search-form">
					<a-form-model-item>
						<a-radio-group v-model="search.typeFlag" button-style="outline">
					        <a-radio-button value="1">
					          	日
					        </a-radio-button>
					        <a-radio-button value="2">
					          	月
					        </a-radio-button>
					        <a-radio-button value="3">
					          	年
					        </a-radio-button>
				      	</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="起始月分:" v-show="search.typeFlag == 2">
						<a-month-picker v-model="search.startMonth" placeholder="选择月分" :locale="datePickerLocale" style="width: 120px;" @change="onChange()"/>
					</a-form-model-item>
					<a-form-model-item label="结束月分:" v-show="search.typeFlag == 2">
						<a-month-picker v-model="search.endMonth" placeholder="选择月分" :locale="datePickerLocale" style="width: 120px;" @change="onChange()"/>
					</a-form-model-item>
					<a-form-model-item label="起始日期:" v-show="search.typeFlag == 1">
						<a-date-picker v-model="search.startTime" placeholder="选择日期" :locale="datePickerLocale" style="width: 120px;" :format="dateFormat" @change="onChange()"/>
					</a-form-model-item>
					<a-form-model-item label="结束日期:" v-show="search.typeFlag == 1">
						<a-date-picker v-model="search.endTime" placeholder="选择日期" :locale="datePickerLocale" style="width: 120px;" :format="dateFormat" @change="onChange()"/>
					</a-form-model-item>
					<a-form-model-item label="设备类型:" size="mini" v-show="tabflag < 3">
						<a-select v-model="search.equipmentType" placeholder="请选择" @change="select(1)" style="width: 80px;">
							<a-select-option v-for="item in equipTypeList"  v-if="item.id < 4" :key="item.id" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="设备别名:" editable v-show="tabflag < 3">
						<a-select v-model="search.equipmentId" filterable placeholder="请选择" @change="select(0)" style="width: 100px;">
							<a-select-option key="全部" value="-1">全部</a-select-option>
							<a-select-option v-for="item in equipmentList" :key="item.id" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" @click="searchFn">查询</a-button>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" @click="exportFn">导出</a-button>
					</a-form-model-item>
					<a-form-model-item style="float: right;">
						<img :src="bt_table" @mouseover="changeBt(1)" @mouseout="changeBt(0)" style="cursor: pointer;" @click="toChart"/>
					</a-form-model-item>
				</a-form-model>
			</div>
			<div>
				<div class="income_tab" id="income" @click="showChart(1)">收益</div>
				<div class="income_tab" id="electricity" @click="showChart(2)">电量</div>
				<div class="income_tab" id="demand" @click="showChart(3)">需量</div>
			</div>
			<div id="income_chart">
			
			</div>
			
			<div style="margin-top: 10px;">
				<a-row type="flex" justify="space-around" align="middle" >
					<a-col :span="4">
						<div class="income_power_item">
							<div style="padding: 20px 10px; float: left;">
								<img src="@a/images/icon/ic_charge_money.png" width="60px"/>
							</div>
							<div style="padding: 20px 10px 20px 0; float: right;">
								<div class="income_power_item_name">
									本月节省电费(元)
								</div>
								<div class="income_power_item_value">
									{{cumulativeIncome}}
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="income_power_item">
							<div style="padding: 20px 10px; float: left;">
								<img src="@a/images/icon/ic_charge_chongdian.png" width="60px"/>
							</div>
							<div style="padding: 20px 10px 20px 0; float: right;">
								<div class="income_power_item_name">
									昨日充电量(kWh)
								</div>
								<div class="income_power_item_value">
									{{chargeAmount}}
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="income_power_item">
							<div style="padding: 20px 10px; float: left;">
								<img src="@a/images/icon/ic_charge_month_chongdian.png" width="60px"/>
							</div>
							<div style="padding: 20px 10px 20px 0; float: right;">
								<div class="income_power_item_name">
									本月充电量(kWh)
								</div>
								<div class="income_power_item_value">
									{{cumulativeChargeAmount}}
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="income_power_item">
							<div style="padding: 20px 10px; float: left;">
								<img src="@a/images/icon/ic_charge_fangdian.png" width="60px"/>
							</div>
							<div style="padding: 20px 10px 20px 0; float: right;">
								<div class="income_power_item_name">
									昨日放电量(kWh)
								</div>
								<div class="income_power_item_value">
									{{dischargeAmount}}
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="income_power_item">
							<div style="padding: 20px 10px; float: left;">
								<img src="@a/images/icon/ic_charge_month_fangdian.png" width="60px"/>
							</div>
							<div style="padding: 20px 10px 20px 0; float: right;">
								<div class="income_power_item_name">
									本月放电量(kWh)
								</div>
								<div class="income_power_item_value">
									{{cumulativeDischargeAmount}}
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="income_power_item">
							<div style="padding: 20px 10px; float: left;">
								<img src="@a/images/icon/ic_charge_money.png" width="60px"/>
							</div>
							<div style="padding: 20px 10px 20px 0; float: right;">
								<div class="income_power_item_name">
									昨日充放电效率
								</div>
								<div class="income_power_item_value" v-if="!effect_is_error">
									{{effectiveness}}%
								</div>
                <div class="income_power_item_value" v-else>
									--
								</div>
							</div>
						</div>
					</a-col>
				</a-row>
			</div>
		</div>
	</div>
</template>

<script>
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import moment from 'moment';
export default {
	name: 'income_chart',
	data() {
		return {
			tabflag: 1,
			bt_table: require("@a/images/bt_chart_nomal.png"),
			moment,
			datePickerLocale: datePickerLocale,
			dateFormat: 'YYYY-MM-DD',
			equipTypeList: [],
			equipmentList: [],
			data: [],
			chart_x: [],
			incomeArray: [],
			sharpPeriodChargeElectricEnergy: [],
			peakPeriodChargeElectricEnergy: [],
			flatPeriodChargeElectricEnergy: [],
			valleyPeriodChargeElectricEnergy: [],
			sharpPeriodDischargeElectricEnergy: [],
			peakPeriodDischargeElectricEnergy: [],
			flatPeriodDischargeElectricEnergy: [],
			valleyPeriodDischargeElectricEnergy: [],
			demandReal: [],
			demandWithEnergyStorage: [],
			chargeAmount: 0,
			cumulativeChargeAmount: 0,
			dischargeAmount: 0,
			cumulativeDischargeAmount: 0,
			cumulativeIncome: 0,
			effectiveness: 0,
			incomeChart: null,
			search: {
				stationId: localStorage.getItem('stationId'),
				signalId: -1,
				equipmentType: 1,
				startMonth: moment().subtract(10, 'month'),
				endMonth: moment(),
				startTime: moment().subtract(1, 'month'),
				endTime: moment(),
				equipmentId: '-1',
				length: 10000000000,
				page: 1,
				typeFlag: '1',
				startIndex: 0
			},
			positiveActiveEnergyInitialValue: 0,
			reverseActiveEnergyInitialValue: 0,
      effect_is_error: false
		}
	},
	mounted() {
		if(this.$route.params.startTime && this.$route.params.endTime ){
			this.search = this.$route.params;
		}
		var dom = $("#income");
		if(this.tabflag == 2){
			dom = $("#electricity");
		}
		dom.css('color', '#1890FF');
		dom.css('border-bottom', '2px solid #1890FF');
		this.getEquipTypeList();
		this.getEquipmentList();
	},
	methods: {
		showChart(id){
			if(this.tabflag == id){
				return;
			}
			this.incomeChart.dispose();
			this.tabflag = id;
			var incomeDom = $("#income");
			var electricityDom = $("#electricity");
			var damendDom = $("#demand");
			if(this.tabflag == 2){
				electricityDom.css('color', '#1890FF');
				electricityDom.css('border-bottom', '2px solid #1890FF');
				incomeDom.css('color', '#B7B8BC');
				incomeDom.css('border-bottom', '1px solid rgba(255,255,255,0.12)');
				damendDom.css('color', '#B7B8BC');
				damendDom.css('border-bottom', '1px solid rgba(255,255,255,0.12)');
			} else if(this.tabflag == 1){
				incomeDom.css('color', '#1890FF');
				incomeDom.css('border-bottom', '2px solid #1890FF');
				electricityDom.css('color', '#B7B8BC');
				electricityDom.css('border-bottom', '1px solid rgba(255,255,255,0.12)');
				damendDom.css('color', '#B7B8BC');
				damendDom.css('border-bottom', '1px solid rgba(255,255,255,0.12)');
			} else if(this.tabflag == 3){
				damendDom.css('color', '#1890FF');
				damendDom.css('border-bottom', '2px solid #1890FF');
				electricityDom.css('color', '#B7B8BC');
				electricityDom.css('border-bottom', '1px solid rgba(255,255,255,0.12)');
				incomeDom.css('color', '#B7B8BC');
				incomeDom.css('border-bottom', '1px solid rgba(255,255,255,0.12)');
			} else {
				return;
			}
			this.getList();
		},
		toChart(){
			this.$router.push({ name: 'income_table', params: this.search});
		},
		onChange () {
			if(typeof(this.search.startTime) === 'string'){
				this.search.startTime = this.search.startTime.split(" ")[0]
				this.search.startTime = moment(this.search.startTime);
			}
			if(typeof(this.search.endTime) === 'string'){
				this.search.endTime = this.search.endTime.split(" ")[0];
				this.search.endTime =moment(this.search.endTime);
			}
	    },
		changeBt(type){
			if(type == 0){
				this.bt_table = require("@a/images/bt_table_nomal.png");
			} else if(type == 1){
				this.bt_table = require("@a/images/bt_table_activity.png");
			}
		},
		select(type) {
			if(type == 1){
				this.search.equipmentId = '-1';
				this.getEquipmentList();
			} else {
				this.getList();
				this.getData();
			}
			
		},
		getEquipTypeList() {
			var this_ = this;
			axios({
				method: 'get',
				url: '/emind/config/equipment/type/list',
			}).then(function(d) { //
				if(!d){
					this_.$message.error('登录超时，请重新登录', 2, function() {});
					return;
				}
				if(d.data.code === '0') {
					this_.equipTypeList = d.data.data;
				} else {
					this_.$message.error('请求出错', 2, function() {});
				}
			}).catch(function(error) {
				if(error.message != '') {
					this_.$message.error(error.message, 2, function() {});
					return;
				}
			});
		},
		getEquipmentList() {
			var this_ = this;
			var data = {
				stationId: localStorage.getItem('stationId'),
				typeId: this_.search.equipmentType
			}
			axios({
				method: 'post',
				url: '/emind/config/equipment/search_condition',
				data: data
			}).then(function(d) { //
				if(!d){
					this_.$message.error('登录超时，请重新登录', 2, function() {});
					return;
				}
				if(d.data.code === '0') {
					this_.equipmentList = d.data.data;
					this_.equipmentList.sort(this_.sortNum);
					if(this_.equipmentList.length > 0){
						this_.search.equipmentId = this_.equipmentList[0].id;
					}
				} else {
					this_.$message.error('请求出错', 2, function() {});
				}
				this_.getList();
				this_.getData();
			}).catch(function(error) {
				if(error.message != '') {
					this_.$message.error(error.message, 2, function() {});
					return;
				}
			});
		},
		sortNum(a,b){  
			if(a.typeId == 3 && b.typeId == 3){
				return (a.num-b.num) * 1; 
			} else if(a.typeId == 3){
				return -1;
			} else {
				return a.typeId - b.typeId;
			}
	   	},
		getList() {
			var this_ = this;
			var data = {
				stationId: localStorage.getItem('stationId'),
				equipmentType: this_.search.equipmentType,
				startTime: this_.search.startTime,
				endTime: this_.search.endTime,
				length: this_.search.length,
				page: this_.search.page,
				startIndex: this_.search.startIndex,
			};
			if(this_.search.typeFlag == 2){
				if(this.search.startMonth != null && this.search.endMonth != null && this.search.startMonth.toDate().getTime() > this.search.endMonth.toDate().getTime()) {
					this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
					return;
				} else {
					var dataTime = '';
					if(this_.search.startMonth != null) {
						dataTime = this_.search.startMonth.format('YYYY-MM') + "-01 00:00:00";
						data.startTime = dataTime;
					} else {
						data.startTime = '1970-01-01 00:00:00'
					}
					if(this_.search.endMonth != null) {
						dataTime = this_.search.endMonth.format('YYYY-MM-DD HH:mm:ss') ;
						data.endTime = dataTime;
					} else {
						if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {
							
						} else {
							data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
						}
					}
				}
				data.name='%Y-%m';
			} else if(this_.search.typeFlag == 3){
				data.startTime = moment().subtract(50, 'year').format('YYYY') + "-01-01 00:00:00";
				data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
				data.name='%Y';
			} else {
				if(this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
					this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
					return;
				} else {
					var dataTime = '';
					if(this_.search.startTime != null) {
						dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
						data.startTime = dataTime;
					} else {
						data.startTime = '1970-01-01 00:00:00'
					}
					if(this_.search.endTime != null) {
						dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
						data.endTime = dataTime;
					} else {
						if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
							data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
						}
					}
				}
				data.name='%Y-%m-%d';
			}
			if(this.search.equipmentType != 1){
				data.equipmentId = this_.search.equipmentId;
				data.signalId = -1;
				if(this.search.equipmentType == 2){
					data.equipmentType = 1;
				}
			}
			
			data.startIndex = (data.page - 1) * data.length;
			var url = '/emind/history/data/electricity/statistics'
			if(this_.tabflag == 3){
				url = '/emind/history/data/station/demand';
			}
			axios({
				method: 'post',
				url: url,
				data: data
			}).then(function(d) { //
				if(!d){
					this_.$message.error('登录超时，请重新登录', 2, function() {});
					return;
				}
				this_.data = d.data.data;
				this_.reflush(this_.tabflag);
			}).catch(function(error) {
				if(error.message != '') {
					this_.$message.error(error.message, 2, function() {});
					return;
				}
			});
		},
		getEnergyInitialValue(){
			var this_ = this;
			axios({
				method: 'get',
				url: '/emind/config/equipment/standardize/energy/' + localStorage.getItem('stationId') + '/6/0',
			}).then(function(d) { //
				if(!d){
					this_.$message.error('登录超时，请重新登录', 2, function() {});
					return;
				}
				if(d.data.data != null){
					this_.positiveActiveEnergyInitialValue = d.data.data.positiveActiveEnergyInitialValue;
					this_.reverseActiveEnergyInitialValue = d.data.data.reverseActiveEnergyInitialValue;
				}
			}).catch(function(error) {
				if(error.message != '') {
					this_.$message.error(error.message, 2, function() {});
					return;
				}
			});
		},
		getData() {
			var this_ = this;
			this_.getEnergyInitialValue();
			var now = moment();
			var data = {
				stationId: localStorage.getItem('stationId'),
				equipmentType: 1,
				endTime: now.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
				startTime: now.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
				length: 1,
				page: 1,
				startIndex: 0,
				name: '%Y-%m-%d'
			};
			if(this.search.equipmentType != 1){
				data.equipmentId = this_.search.equipmentId;
				data.signalId = -1;
				if(this.search.equipmentType == 2){
					data.equipmentType = 1;
				}
			}
			var url = '/emind/history/data/electricity/statistics';
			this_.cumulativeChargeAmount = 0.00;
			this_.chargeAmount = 0.00;
			this_.cumulativeDischargeAmount = 0.00;
			this_.dischargeAmount = 0.00;
			this_.cumulativeIncome = 0.00;
			this_.averageIncome = 0.00;
			this_.effectiveness = 0;
      this.effect_is_error = false
			axios({
				method: 'post',
				url: url,
				data: data
			}).then((d) =>{ //
				if(!d){
					this_.$message.error('登录超时，请重新登录', 2, function() {});
					return;
				}
				if(d.data.data.length > 0) {
					this_.chargeAmount = d.data.data[0].chargeAmount.toFixed(1);
					this_.dischargeAmount = d.data.data[0].dischargeAmount.toFixed(1);
					if(this_.chargeAmount > 0.5){
						this_.effectiveness = (this_.dischargeAmount * 100 / this_.chargeAmount).toFixed(2);
            if (this_.effectiveness < 80 || this.effectiveness > 95) {
              this.effect_is_error = true
            }else{
              this.effect_is_error = false
            }
					}
				}
			}).catch((error)=> {
				if(error.message != '') {
					this_.$message.error(error.message, 2, function() {});
					return;
				}
			});
			now = moment();
			data.name = '%Y-%m',
			data.endTime = now.format('YYYY-MM-DD HH:mm:ss')
			data.startTime = now.format('YYYY-MM') + '-01 00:00:00'
			axios({
				method: 'post',
				url: url,
				data: data
			}).then(function(d) { //
				if(!d){
					this_.$message.error('登录超时，请重新登录', 2, function() {});
					return;
				}
				if(d.data.data.length > 0) {
					this_.cumulativeIncome = d.data.data[0].todayInCome.toFixed(2);
					this_.cumulativeDischargeAmount = d.data.data[0].dischargeAmount.toFixed(1);
					this_.cumulativeChargeAmount = d.data.data[0].chargeAmount.toFixed(1);
				}
			}).catch(function(error) {
				if(error.message != '') {
					this_.$message.error(error.message, 2, function() {});
					return;
				}
			});
		},
		reflush(tabflag) {
			var this_ = this;
			if(this_.tabflag != tabflag){
				return;
			}
			this_.chart_x = [];
			if(this_.tabflag < 3){
				this_.sharpPeriodChargeElectricEnergy = [];
				this_.peakPeriodChargeElectricEnergy = [];
				this_.flatPeriodChargeElectricEnergy = [];
				this_.valleyPeriodChargeElectricEnergy = [];
				this_.sharpPeriodDischargeElectricEnergy = [];
				this_.peakPeriodDischargeElectricEnergy = [];
				this_.flatPeriodDischargeElectricEnergy = [];
				this_.valleyPeriodDischargeElectricEnergy = [];
				this_.incomeArray = [];
				var newOp = this_.getOption();
				if(this_.data.length > 0) {
					for(var i = this_.data.length - 1; i >= 0; i--) {
						recordDate = this_.data[i].date;
						this_.incomeArray.push([this_.data[i].date, this_.data[i].todayInCome]);
						this_.sharpPeriodChargeElectricEnergy.push([this_.data[i].date, this_.data[i].sharpPeriodChargeElectricEnergy]);
						this_.peakPeriodChargeElectricEnergy.push([this_.data[i].date, this_.data[i].peakPeriodChargeElectricEnergy]);
						this_.flatPeriodChargeElectricEnergy.push([this_.data[i].date, this_.data[i].flatPeriodChargeElectricEnergy]);
						this_.valleyPeriodChargeElectricEnergy.push([this_.data[i].date, this_.data[i].valleyPeriodChargeElectricEnergy]);
						this_.sharpPeriodDischargeElectricEnergy.push([this_.data[i].date, this_.data[i].sharpPeriodDischargeElectricEnergy]);
						this_.peakPeriodDischargeElectricEnergy.push([this_.data[i].date, this_.data[i].peakPeriodDischargeElectricEnergy]);
						this_.flatPeriodDischargeElectricEnergy.push([this_.data[i].date, this_.data[i].flatPeriodDischargeElectricEnergy]);
						this_.valleyPeriodDischargeElectricEnergy.push([this_.data[i].date, this_.data[i].valleyPeriodDischargeElectricEnergy]);
					}
				}
			} else {
				this_.damendReal = [];
				this_.demandWithEnergyStorage = [];
				var newOp = this_.getOption();
				var recordDate = "";
				if(this_.data.length > 0) {
					for(var i = this_.data.length - 1; i >= 0; i--) {
						recordDate = new Date(this_.data[i].date.toString().replace(/-/g, "/")).Format("yyyy-MM-dd");
						this_.damendReal.push([recordDate, this_.data[i].realDemand]);
						this_.demandWithEnergyStorage.push([recordDate, this_.data[i].demandWithEnergyStorage]);
					}
				}
			}
			if(this_.search.typeFlag == 1){
				recordDate = this_.search.endTime;
				if(recordDate == null){
					recordDate = moment();
				}
				recordDate = moment(this_.search.startTime.format('YYYYMMDD'), "YYYYMMDD");
				this_.chart_x.push(recordDate.format('YYYY-MM-DD'));
				while(recordDate.unix() * 1000 < this_.search.endTime.unix() * 1000){
					this_.chart_x.push(recordDate.subtract(-1, 'day').format('YYYY-MM-DD'));
				}
			} else if(this_.search.typeFlag == 2){
				recordDate = moment(this_.search.startMonth.format('YYYYMMDD'), "YYYYMMDD")
				this_.chart_x.push(recordDate.format('YYYY-MM'));
				while(recordDate.unix() * 1000 < this_.search.endMonth.unix() * 1000){
					this_.chart_x.push(recordDate.subtract(-1, 'month').format('YYYY-MM'));
				}
			} else {
				var count = this_.data.length;
				if(count < 9){
					count = 9
				}
				for(var i = count; i > -2 ; i --){
					this_.chart_x.push(moment().format('YYYY') - i);
				}
			}
			if(this_.data.length < 1) {
				newOp.title = {
					textStyle: {
						fontFamily: "微软雅黑",
						color: '#E2E2E2'
					},
					text: this_.tabflag == 1 ? '储能收益柱状图' : (this_.tabflag == 2 ? '电能统计柱状图' :  '电站需量统计曲线'),
					subtext: '\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据',
					x: 'center'
				};
			} else {
				newOp.title = {
					textStyle: {
						fontFamily: "微软雅黑",
						color: '#E2E2E2'
					},
					text: this_.tabflag == 1 ? '储能收益柱状图' : (this_.tabflag == 2 ? '电能统计柱状图' :  '电站需量统计曲线'),
					subtext: '',
					x: 'center'
				};
			}
			if(this_.tabflag == 1){
				newOp.series = [{
					itemStyle: {
						color: '#1890FF'
					},
					name: '收益(元)',
					type: 'bar',
					barWidth: '60%',
					data: this_.incomeArray
				}];
				newOp.tooltip = {
					trigger: 'axis',
					formatter: function(params) {
						var relVal = params[0].name + "<br/>" + params[0].value[1] + ' 元';
						return relVal;
					}
				};
				newOp.yAxis = {
					nameTextStyle: {
						fontFamily: "微软雅黑",
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: '#7C868D',
						}
					},
					name: '收益(元)',
					type: 'value',
				}
			} else if(this_.tabflag == 2){
				newOp.tooltip = {
					trigger: 'axis'
				};
				newOp.yAxis = {
					nameTextStyle: {
						fontFamily: "微软雅黑",
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: '#7C868D',
						}
					},
					name: '电量(kWh)',
					type: 'value',
				};
				newOp.series = [{
						name: '放电（尖）',
						type: 'bar',
						stack: '放电',
						color: '#F2E3DA',
						data: this_.sharpPeriodDischargeElectricEnergy,
					},
					{
						name: '放电（峰）',
						type: 'bar',
						stack: '放电',
						color: '#F2B691',
						data: this_.peakPeriodDischargeElectricEnergy,
					},
					{
						name: '放电（平）',
						type: 'bar',
						stack: '放电',
						color: '#F29861',
						data: this_.flatPeriodDischargeElectricEnergy,
					},
					{
						name: '放电（谷）',
						type: 'bar',
						stack: '放电',
						color: '#F26813',
						data: this_.valleyPeriodDischargeElectricEnergy,
					},
					{

						name: '充电（谷）',
						type: 'bar',
						stack: '充电',
						color: '#1890FF',
						data: this_.valleyPeriodChargeElectricEnergy,
					},
					{
						name: '充电（峰）',
						type: 'bar',
						stack: '充电',
						color: '#B3DAFF',
						data: this_.peakPeriodChargeElectricEnergy,
					},
					{
						name: '充电（平）',
						type: 'bar',
						stack: '充电',
						color: '#66B5FF',
						data: this_.flatPeriodChargeElectricEnergy,
					},
					{
						name: '充电（尖）',
						type: 'bar',
						stack: '充电',
						color: '#E6F3FF',
						data: this_.sharpPeriodChargeElectricEnergy,
					}
				];
			} else {
				newOp.yAxis = {
					nameTextStyle: {
						fontFamily: "微软雅黑",
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: '#7C868D',
						}
					},
					name: '需量(kW)',
					type: 'value',
				};
				newOp.series = [{
					itemStyle: {
						color: '#F26813'
					},
					name: '实际需量(kW)',
					type: 'line',
					data: this_.damendReal
				},{
					itemStyle: {
						color: '#1890FF'
					},
					name: '储能接入需量(kW)',
					type: 'line',
					data: this_.demandWithEnergyStorage
				}];
				newOp.tooltip = {
					trigger: 'axis',
					formatter: function(params) {
						var relVal = params[0].name + "：" + params[0].value[1] + ' kW'
									+ '<br/>'
									+ params[1].name + "：" + params[1].value[1] + ' kW';
						return relVal;
					}
				};
			}
			
			newOp.xAxis.data = this_.chart_x;
			this_.incomeChart = this_.$echarts.init(document.getElementById("income_chart"));
			this_.incomeChart.setOption(newOp);
			setTimeout(() => {
				window.onresize = function() {
					this_.incomeChart.resize();
				}
			}, 500);
		},
		getOption(){
			var this_ = this;
			return {
				title: {
					textStyle: {
						fontFamily: "微软雅黑",
						color: '#E2E2E2'
					},
					text: '储能收益柱状图',
					x: 'center'
				},
				tooltip: {
					trigger: 'axis',
					formatter: function(params) {
						var relVal = params[0].name + "<br/>" + params[0].value[1] + ' 元';
						return relVal;
					}
				},
				toolbox: {
					show: true,
					right: 20,
					feature: {
						dataZoom: {
							yAxisIndex: 'none'
						},
						restore: {},
						saveAsImage: {}
					}
				},
				grid: {
					left: 50,
					right: 60,
					top: '35',
					height: '80%'
				},
				xAxis: {
					nameTextStyle: {
						fontFamily: "微软雅黑",
					},
					splitLine: {
						show: false,
					},
					axisLine: {
						onZero: false,
						lineStyle: {
							color: '#7C868D',
						}
					},
					type: 'category',
					data: this_.chart_x,
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					nameTextStyle: {
						fontFamily: "微软雅黑",
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: '#7C868D',
						}
					},
					name: '收益(元)',
					type: 'value',
				},
				series: [{
					itemStyle: {
						color: '#1890FF'
					},
					name: '收益(元)',
					type: 'bar',
					barWidth: '60%',
					data: this_.incomeArray
				}]
			};
			if(this_.chart_x == null || this_.chart_x.length < 1) {
				this_.incomeOption.title = {
					textStyle: {
						fontFamily: "微软雅黑",
					},
					text: '储能收益柱状图',
					subtext: '\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据',
					x: 'center'
				};
			};
		},
		searchFn() {
			var this_ = this;
			this_.getList();
			this_.getData();
		},
		exportExcel(url, options = {}, name) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: url, // 请求地址
					data: options, // 参数
					responseType: 'blob' // 表明返回服务器返回的数据类型
				}).then(
					response => {
						resolve(response.data)
						let blob = new Blob([response.data], {
							type: 'application/vnd.ms-excel'
						})
						var fileName = name;
						if(response.headers['content-disposition'].endsWith('.xls"')) {
							fileName = fileName + '.xls';
						} else if(response.headers['content-disposition'].endsWith('.zip"')) {
							fileName = fileName + '.zip';
						}
						if(window.navigator.msSaveOrOpenBlob) {
							navigator.msSaveBlob(blob, fileName)
						} else {
							var link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = fileName
							link.click()
							//释放内存
							window.URL.revokeObjectURL(link.href)
						}
					},
					err => {
						if(error.message != '') {
							this_.$message.error(error.message, 2, function() {});
							return;
						}
					}
				)
			})
		},
		exportFn() {
			var this_ = this;
			var data = {
				stationId: localStorage.getItem('stationId'),
				equipmentType: this_.search.equipmentType,
				startTime: this_.search.startTime,
				endTime: this_.search.endTime,
				length: this_.search.length,
				page: this_.search.page,
				startIndex: this_.search.startIndex,
			};
			if(this_.search.typeFlag == 2){
				if(this.search.startMonth != null && this.search.endMonth != null && this.search.startMonth.toDate().getTime() > this.search.endMonth.toDate().getTime()) {
					this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
					return;
				} else {
					var dataTime = '';
					if(this_.search.startMonth != null) {
						dataTime = this_.search.startMonth.format('YYYY-MM') + "-01 00:00:00";
						data.startTime = dataTime;
					} else {
						data.startTime = '1970-01-01 00:00:00'
					}
					if(this_.search.endMonth != null) {
						dataTime = this_.search.endMonth.format('YYYY-MM-DD HH:mm:ss');
						data.endTime = dataTime;
					} else {
						if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {
							
						} else {
							data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
						}
					}
				}
				data.name='%Y-%m';
			} else if(this_.search.typeFlag == 3){
				data.startTime = moment().subtract(10, 'year').format('YYYY') + "-01-01 00:00:00";
				data.endTime = moment().subtract(-1, 'month').format('YYYY-MM') + "-01 00:00:00";
				data.name='%Y';
			} else {
				if(this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
					this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
					return;
				} else {
					var dataTime = '';
					if(this_.search.startTime != null) {
						dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
						data.startTime = dataTime;
					} else {
						data.startTime = '1970-01-01 00:00:00'
					}
					if(this_.search.endTime != null) {
						dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
						data.endTime = dataTime;
					} else {
						if(this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
							data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
						}
					}
				}
				data.name='%Y-%m-%d';
			}
			if(this.search.equipmentType != 1){
				data.equipmentId = this_.search.equipmentId;
				data.signalId = -1;
				if(this.search.equipmentType == 2){
					data.equipmentType = 1;
				}
			}
			var now = new Date();
			var url = '/emind/history/data/electricity/statistics/export/'
			var name = '电量计量-'
			if(this_.tabflag == 3){
				url = '/emind/history/data/station/demand/export/';
				name = '电站需量-'
			}
			url = url + now.getTime();
			this_.exportExcel(url, data, name + now.Format("yyyy-MM-dd_hh-mm-ss"));
		}
	}
}
</script>

<style>
@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.income_tab{
	padding: 10px 0;
	border-bottom: 1px solid rgba(255,255,255,0.12);
	cursor: pointer;
	text-align: center;
	float: left;
	width: 80px;
	font-family: PingFangSC-Regular;
	font-size: 16px;
	color: #B7B8BC;
	line-height: 24px;
	font-weight: 600;
}
#income_chart{
	margin-top: 60px;
	width: 100%;
	height: 580px;
}
.income_power_item{
	width: 95%;
	height: 100px;
	background: #71707085;
	border-radius: 2px;
}
.income_power_item_name{
	height: 22px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 22px;
	font-weight: 400;
}
.income_power_item_value{
	height: 38px;
	font-family: HelveticaNeue-Medium;
	font-size: 26px;
	color: #E2E2E2;
	line-height: 38px;
	font-weight: 500;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff!important;
    background: transparent !important;
    border-color: #1890ff;
    box-shadow: -1px 0 0 0 #1890ff;
}
.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(255, 255, 255, 0.65) !important;
    line-height: 30px;
    background: transparent !important;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left: 0;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
}
</style>